import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

class Row extends React.Component{
  
  render(){ 
    //console.log('active', this.props.rowData.active);
    var active = (this.props.rowData.key !== this.props.activeKey) ? 'start' : 'add';
    var stopBtn = (this.props.rowData.key !== this.props.activeKey) ? '' : <button  className="btn btn-warning btn-sm mr-1" onClick={() => this.props.stopClick()} >stop</button>;
    var displayTime = (this.props.rowData.key !== this.props.activeKey) ? '' : this.props.displayTime;
    console.log('displayTime', this.props.displayTime);
    return(
      <tr>
        <td>{this.props.rowData.sessionTitle}</td>
        <td>{this.props.rowData.displayTimeSpent}</td>
        <td>{displayTime}</td>
        <td>
          <button className="btn btn-success btn-sm mr-1" onClick={() => this.props.startClick()} >{active}</button>
          {stopBtn}
          <button className="btn btn-danger btn-sm" onClick={() => this.props.deleteClick()} >delete</button>
        </td>
      </tr>
    );
  }
}

class Timer extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      rows: [],
      sessionId: '',
      sessionTitle: '',
      activeKey: null,
      displayTime: null,
    };
  }

  apiHandler(action){
    var proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    //var targetUrl = 'https://timer.andytorrance.com/assets/ajax-handler-session.php';
    var targetUrl = 'https://timer.andytorrance.com/assets/ajax-handler-db.php';
    //fetch(proxyUrl + targetUrl, {
    fetch(targetUrl, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action),
    })
    //fetch(targetUrl)
      .then(res => res.json())
      .then(
        (result) => {  console.log(result);
          this.setState({
            isLoaded: true,
            rows: result.rows,
            sessionId: result.sessionId,
            displaySessionId: result.sessionId,
            activeKey: result.activeKey,
            displayTime: result.displayTime,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      ) 
  }

  componentDidMount() {
    var action = {action: ''};
    this.apiHandler(action);
  }

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;

    this.setState({[nam]: val});
  }

  createSession = (event) => {
    event.preventDefault();

    this.setState({sessionTitle: ''});

    let action = {action: 'createSession', sessionId: this.state.sessionId, sessionTitle: this.state.sessionTitle};
    this.apiHandler(action);
  }

  retrieveSession = (event) => {
    event.preventDefault();

    let action = {action: 'retrieveSession', sessionId: this.state.sessionId}; console.log('retrieveSession', this.state.sessionId)
    this.apiHandler(action);
  }

  clearAll(){
    if(window.confirm('You are about to clear all sessions')){
      let action = {action: 'unset'};
      this.apiHandler(action);
    }
  }
  
  btnHandler(key, action){
    //console.log(key, action);
    let theAction = {action: action, key:key};
    this.apiHandler(theAction);
  }  

  render(){
    var rows = [];
    for(var i = 0; i < this.state.rows.length; i++){
      let theKey = this.state.rows[i].key;
      rows.push(
        <Row 
          rowData={this.state.rows[i]} 
          key={this.state.rows[i].key}
          activeKey = {this.state.activeKey}
          displayTime = {this.state.displayTime}
          startClick={() => this.btnHandler(theKey, 'start')}
          stopClick={() => this.btnHandler(theKey, 'stop')}
          deleteClick={() => this.btnHandler(theKey, 'delete')}
        />
      );
    }
    if(this.state.rows.length > 0)
      var sessionRememberText = <p>Take a note of your Session ID <b>{this.state.displaySessionId}</b> so your session can be retrieved.</p>;
    else
      var sessionRememberText = '';

    return(
      <div id="main-container" className="container-fluid">

          <div className="row  text-center">
            <div className="col-12">
              <h1>Work Timer</h1>
              {sessionRememberText}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-4">
              <form className="form-inline float-left" onSubmit={this.createSession}>
                <div className="form-group">
                <input type='text' className="form-control mr-1" name="sessionTitle" placeholder="Item Title" onChange={this.myChangeHandler} value={this.state.sessionTitle} />
                <input type="submit" className="btn btn-success" value="Add Item" />
                </div>
              </form>
            </div>

            <div className="col-md-4">
              <form className="form-inline float-left" onSubmit={this.retrieveSession}>
                <div className="form-group">
                <input type='text' className="form-control mr-1" name="sessionId" placeholder="Session ID" onChange={this.myChangeHandler} value={this.state.sessionId} />
                <input type="submit" className="btn btn-success" value="Retrieve Session" />
                </div>
              </form>
            </div>

            <div className="col-md-4">
              <button className="btn btn-danger float-right" onClick={() => this.clearAll()}>Clear All</button>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                  <th>WORK SESSION</th>
                  <th>TIME SPENT</th>
                  <th>CURRENT START TIME</th>
                  <th align="center">ACTIONS</th>
                  </tr>
                </thead>
                  <tbody>
                    {rows}
                  </tbody>
              </table> 
            </div>
          </div>

        
      </div>
    );
  }
}

ReactDOM.render(
  <Timer />,
  document.getElementById('root')
);
